/* import fonts */

@font-face {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/EBGaramond-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "EB Garamond";
  font-style: italic;
  font-weight: 400;
  src: url(../assets/fonts/EBGaramond-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 500;
  src: url(../assets/fonts/EBGaramond-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "EB Garamond";
  font-style: italic;
  font-weight: 500;
  src: url(../assets/fonts/EBGaramond-MediumItalic.ttf) format("truetype");
}

@font-face {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 600;
  src: url(../assets/fonts/EBGaramond-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "EB Garamond";
  font-style: italic;
  font-weight: 600;
  src: url(../assets/fonts/EBGaramond-SemiBoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 700;
  src: url(../assets/fonts/EBGaramond-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "EB Garamond";
  font-style: italic;
  font-weight: 700;
  src: url(../assets/fonts/EBGaramond-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 800;
  src: url(../assets/fonts/EBGaramond-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "EB Garamond";
  font-style: italic;
  font-weight: 800;
  src: url(../assets/fonts/EBGaramond-ExtraBoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  src: url(../assets/fonts/Poppins-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url(../assets/fonts/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  src: url(../assets/fonts/Poppins-MediumItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url(../assets/fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  src: url(../assets/fonts/Poppins-SemiBoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url(../assets/fonts/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  src: url(../assets/fonts/Poppins-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  src: url(../assets/fonts/Poppins-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 800;
  src: url(../assets/fonts/Poppins-ExtraBoldItalic.ttf) format("truetype");
}

html {
  overflow: hidden;
  height: 100%;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

a {
  color: #000;
  text-decoration: none;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1;
  height: 100%;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.nonSidebarContent {
  max-width: 100%;
}
